import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Kotisivu from "./pages/Kotisivu";
const moment = require("moment");
const taulogo = require("./logohighres.png");

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Homepage />}> */}
        {/* Julkiset sivut */}
        <Route path="/" element={<Homepage />} />
        <Route path="/kotisivu" element={<Kotisivu />} />
        {/* <Route path="/kotisivu" element={<LoginPage />} /> */}
        <Route path="*" element={<p>404</p>} />
        {/* </Route> */}
      </Routes>
    </Router>
  );
};

export default App;
