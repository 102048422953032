import React, { useEffect, useState } from "react";
const moment = require("moment");
const taulogo = require("../logohighres.png");

const Homepage = () => {
    const [data, setData] = useState();
    // console.log("LOWEST: ", lowest);
    // console.log(sauna);
  
    useEffect(() => {
      const source = new EventSource(`https://tempapi.gprofile.eu/api/dashboard`);
  
      source.addEventListener("open", () => {
        console.log("SSE opened!");
      });
  
      source.addEventListener("message", (e) => {
        // console.log(e.data);
        const data = JSON.parse(e.data);
  
        // console.log(JSON.parse(e.data));
        console.log(data);
  
        setData(data);
        // console.log(data.vesi);
        // if (lowest > data.water) setLowest(data.water);
        // console.log(data.water, lowest);
      });
  
      source.addEventListener("error", (e) => {
        console.error("Error: ", e);
      });
  
      return () => {
        source.close();
      };
    }, []);
  
    return (
      <div
        style={{
          // height: "80vh",
          width: "100vw",
        }}
      >
        {/* <h1>Temperatures</h1>
        <hr /> */}
        <div
          style={{
            display: "flex",
            // gap: "7vw",
            // width: "100%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "8vw",
            fontFamily: "raleway",
            fontWeight: "bold",
          }}
        >
          {/* VESI */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              width: '45vw'

            }}
          >
            <p style={{ margin: 0 }}>Vesi / Water</p>
            <p style={{ margin: 0 }}>
              {parseFloat(data?.vesi?.temp).toFixed(1)}°C{" "}
            </p>
            <p style={{ fontSize: "3vw", marginTop: "-0.5vw" }}>
              päivitetty: {moment(data?.vesi?.temptime).format("DD.MM | HH:mm")}
            </p>
            {/* <p style={{ fontSize: "3vw", marginBottom: "-3vw" }}>
              alin: {data?.vesi?.templow?.toFixed(1)}°C{" "}
              {moment(data?.vesi?.timelow).format("| HH:mm")}
            </p>
            <p style={{ fontSize: "3vw", marginBottom: "-3vw" }}>
              ylin: {data?.vesi?.temphigh?.toFixed(1)}°C{" "}
              {moment(data?.vesi?.timehigh).format("| HH:mm")}
            </p> */}
            {/* <img src={taulogo} style={{ width: "40vw", marginTop: "5vw" }} /> */}
          </div>
          {/* ULKO */}
          <hr
            style={{
              height: "40vw",
              width: "0.5vw",
              backgroundColor: "black",
              padding: 0,
              margin: "3vw",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              width: '45vw'
            }}
          >
            <p style={{ margin: 0 }}>Ilma / Air</p>
            <p style={{ margin: 0 }}>
              {parseFloat(data?.ulko?.temp).toFixed(1)}°C{" "}
            </p>
            <p style={{ fontSize: "3vw", marginTop: "-1vw" }}>
              päiv: {moment(data?.ulko?.time).format("DD.MM | HH:mm")}
            </p>
          </div>

          {/* SAUNA */}
          {/* <hr
            style={{
              height: "40vw",
              width: "0.5vw",
              backgroundColor: "black",
              padding: 0,
              margin: "5vw",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <p style={{ margin: 0 }}>Sauna</p>
            <p style={{ margin: 0 }}>
              {parseFloat(data?.sauna?.temp).toFixed(1)}°C{" "}
            </p>
            <p style={{ fontSize: "3vw", marginTop: "-1vw" }}>
              päiv: {moment(data?.sauna?.time).format("DD.MM | HH:mm")}
            </p>
          </div> */}

  
        </div>

        {/* LOGO */}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img src={taulogo} style={{ width: "40vw" }} />
        </div>

      </div>
    );
  };
  
  export default Homepage;