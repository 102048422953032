import moment from "moment";
import React, { useEffect, useState } from "react";

const Kotisivu = () => {
  const [data, setData] = useState();
  // console.log("LOWEST: ", lowest);
  // console.log(sauna);

  useEffect(() => {
    const source = new EventSource(`https://tempapi.gprofile.eu/api/dashboard`);

    source.addEventListener("open", () => {
      console.log("SSE opened!");
    });

    source.addEventListener("message", (e) => {
      // console.log(e.data);
      const data = JSON.parse(e.data);

      // console.log(JSON.parse(e.data));
      // console.log(data);

      setData(data);
      // console.log(data.vesi);
      // if (lowest > data.water) setLowest(data.water);
      // console.log(data.water, lowest);
    });

    source.addEventListener("error", (e) => {
      console.error("Error: ", e);
    });

    return () => {
      source.close();
    };
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          // gap: "7vw",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "9vw",
          fontFamily: "raleway",
          fontWeight: "bold",
          WebkitTextStrokeWidth: "0.1vw",
          WebkitTextStrokeColor: "white",
        }}
      >
        {/* VEDEN LÄMPÖTILA */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0 }}>Vesi / Water</p>
          <p style={{ margin: 0 }}>
            {parseFloat(data?.vesi?.temp).toFixed(1)}°C{" "}
          </p>
          <p
            style={{
              fontSize: "1.25rem",
              marginTop: "0.25rem",
              WebkitTextStrokeWidth: "0",
            }}
          >
            päivitetty: {moment(data?.vesi?.temptime).format("DD.MM | HH:mm")}
          </p>
        </div>

        {/* VÄLIVIIVA */}
        <hr
          style={{
            height: "40vh",
            width: "0.25rem",
            backgroundColor: "black",
            padding: 0,
            border: "none",
            margin: "1.5rem",
          }}
        />

        {/* ULKO  */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0 }}>Ilma / Air</p>
          <p style={{ margin: 0 }}>
            {parseFloat(data?.ulko?.temp).toFixed(1)}°C{" "}
          </p>
          <p
            style={{
              fontSize: "1.25rem",
              marginTop: "0.25rem",
              padding: 0,
              WebkitTextStrokeWidth: "0",
            }}
          >
            päivitetty: {moment(data?.ulko?.time).format("DD.MM | HH:mm")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Kotisivu;
